var searchKeys = [{
  key: "name",
  label: "模块名称",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: 'dates',
  label: '开始时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始日期", "截止日期"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };