var goodsColumns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '10%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '商品类目',
  dataIndex: 'parentcategoryname',
  key: 'parentcategoryname',
  width: '20%',
  scopedSlots: {
    customRender: 'parentcategoryname'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  width: '10%',
  scopedSlots: {
    customRender: 'brandname'
  }
}, {
  title: '系列名称',
  dataIndex: 'seriename',
  key: 'seriename',
  width: '10%',
  scopedSlots: {
    customRender: 'seriename'
  }
}, {
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  width: '10%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  width: '15%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '发售价($)',
  dataIndex: 'saleprice',
  key: 'saleprice',
  width: '20%',
  scopedSlots: {
    customRender: 'saleprice'
  }
}];
var brandColumns = [{
  title: '品牌logo',
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '30%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '英文名称',
  dataIndex: 'name',
  key: 'name',
  width: '30%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '中文名称',
  dataIndex: 'cnname',
  key: 'cnname',
  width: '35%',
  scopedSlots: {
    customRender: 'cnname'
  }
}];
export { goodsColumns, brandColumns };